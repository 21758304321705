import React from 'react';
import { useLocation } from "@gatsbyjs/reach-router";
import Logo from "../logo/logo";
import { Link } from "gatsby";
import {skLabsTM} from "../../constants";

const FooterBar = () => {
    const location = useLocation();

    const links = [
        { to: "/company/about#promises", text: "Our future vision?" },
        { to: "/company/partnerships", text: "Our portfolio?" },
        { to: "/services", text: "Our latest services?" },
        { to: "/company/careers", text: "Join our team?" }
    ];

    return (
        <footer className={"flex w-full relative mb-10"}>
            <div className={"relative bg-white/90 custom-container mx-auto rounded-3xl"}>
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="md:w-1/2 p-8 md:pl-20">
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center">
                                <Logo className="h-16"/>
                            </div>
                            <p className="text-3xl font-bold text-zinc-800">{skLabsTM}</p>
                            <p className={"max-w-lg"}>This is our global website, intended for visitors seeking information on {skLabsTM} worldwide
                                business.</p>
                            <div className="text-black/60 pt-2 max-w-lg">
                                <p>Registered Office</p>
                                <p>Unit No. 4, 5th Road, Sarvanand Sadan, Plot No. 570. Khar (West), Mumbai - 400 052.</p>
                            </div>
                            <div className={"flex pt-4 gap-4"}>
                                <Link to=""
                                      className="items-center text-zinc-800 border border-gray-900 hover:border-primary-default rounded-2xl p-2">
                                    Privacy Policy
                                </Link>
                                <Link to=""
                                      className="items-center text-zinc-800 border border-gray-900 hover:border-primary-default rounded-2xl p-2 ">
                                    Legal Statement
                                </Link>
                            </div>

                            <h2 className="text-lg pt-8">
                                © 2024 S.K. Lab Furniture.
                            </h2>
                        </div>
                    </div>

                    <div className="md:w-1/2 p-8 flex flex-col space-y-4">
                        <h3 className="text-4xl font-bold text-zinc-800">Get to Know Us</h3>
                        <div className="flex flex-col space-y-2">
                            {links.map((link, index) => (
                                <Link key={index} to={link.to}
                                      className={"flex items-center justify-start text-xl text-zinc-600 hover:text-primary-default border-b border-gray-300 hover:border-primary-default pb-2"}>
                                    {link.text}
                                </Link>
                            ))}
                        </div>
                        <div className="flex flex-col space-y-4 pt-8">
                            <p className="text-lg font-bold text-zinc-800">Follow us on</p>
                            <div className="flex space-x-4">
                                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"
                                   className="text-zinc-800 hover:text-primary-default">
                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 0C5.83 0 0 5.83 0 13V31C0 38.17 5.83 44 13 44H31C38.17 44 44 38.17 44 31V13C44 5.83 38.17 0 31 0H13ZM34 8C35.1 8 36 8.9 36 10C36 11.1 35.1 12 34 12C32.9 12 32 11.1 32 10C32 8.9 32.9 8 34 8ZM22 11C28.07 11 33 15.93 33 22C33 28.07 28.07 33 22 33C15.93 33 11 28.07 11 22C11 15.93 15.93 11 22 11ZM22 13C17.04 13 13 17.04 13 22C13 26.96 17.04 31 22 31C26.96 31 31 26.96 31 22C31 17.04 26.96 13 22 13Z"
                                            fill="black"/>
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"
                                   className="text-zinc-800 hover:text-primary-default">
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M37 0H5C2.24 0 0 2.24 0 5V37C0 39.76 2.24 42 5 42H37C39.76 42 42 39.76 42 37V5C42 2.24 39.76 0 37 0ZM13 16V35H7V16H13ZM7 10.47C7 9.07 8.2 8 10 8C11.8 8 12.93 9.07 13 10.47C13 11.87 11.88 13 10 13C8.2 13 7 11.87 7 10.47ZM35 35H29C29 35 29 25.74 29 25C29 23 28 21 25.5 20.96H25.42C23 20.96 22 23.02 22 25C22 25.91 22 35 22 35H16V16H22V18.56C22 18.56 23.93 16 27.81 16C31.78 16 35 18.73 35 24.26V35Z"
                                            fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterBar;
