import React, {useLayoutEffect} from "react"
import {useLocation} from "@gatsbyjs/reach-router";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const GsapAnimations = () => {

    const location = useLocation()

    useLayoutEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.config({
            autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
            ignoreMobileResize: true
        })

        ScrollTrigger.getAll().forEach(t => t.kill());

        document.querySelectorAll(`[data-scroll]`).forEach(element => {
            switch ((element as HTMLElement).dataset.scrollType) {
                case "speed":
                    if (element) {
                        const section: HTMLDivElement | null = element as HTMLDivElement;

                        if (document.body.clientWidth >= 1024 || section.dataset.enableForMobile) {
                            const speeding_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);

                            speeding_elements.forEach((el) => {

                                const animation_object: gsap.TweenVars = {
                                    transformOrigin: "center",
                                    ease: "none",
                                    scrollTrigger: {
                                        trigger: section,
                                        end: `bottom top`,
                                        start: "top bottom",
                                        scrub: 2
                                    }
                                }

                                if (el.dataset.scrollDirection && el.dataset.scrollDirection === "horizontal") {
                                    animation_object.x = el.dataset.scrollSpeed ?? -100
                                } else {
                                    animation_object.y = el.dataset.scrollSpeed ?? -100
                                }

                                gsap.to(el, animation_object);
                            })
                        }
                    }
                    break;

                case "speed-mobile":
                    if (element) {
                        const section: HTMLDivElement | null = element as HTMLDivElement;

                        if (document.body.clientWidth >= 300 || section.dataset.enableForMobile) {
                            const speeding_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);

                            speeding_elements.forEach((el) => {

                                const animation_object: gsap.TweenVars = {
                                    transformOrigin: "center",
                                    ease: "none",
                                    scrollTrigger: {
                                        trigger: section,
                                        end: `bottom top`,
                                        start: "top bottom",
                                        scrub: 2
                                    }
                                }

                                if (el.dataset.scrollDirection && el.dataset.scrollDirection === "horizontal") {
                                    animation_object.x = el.dataset.scrollSpeed ?? -100
                                } else {
                                    animation_object.y = el.dataset.scrollSpeed ?? -100
                                }

                                gsap.to(el, animation_object);
                            })
                        }
                    }
                    break;
                case "background-change":
                    if (element) {
                        const section: HTMLDivElement | null = element as HTMLDivElement;
                        const target: HTMLElement | null = document.getElementById(section.dataset.changeTarget ?? "");

                        const animation_object: gsap.TweenVars = {
                            opacity: 1,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                start: "top 80%",
                                end: `top 75%`,
                                scrub: 1
                            }
                        }

                        gsap.fromTo(target, {opacity: 0}, animation_object);
                    }
                    break;
                case "background-change-exit":
                    if (element) {
                        const section: HTMLDivElement | null = element as HTMLDivElement;
                        const target: HTMLElement | null = document.getElementById(section.dataset.changeTarget ?? "");

                        const animation_object: gsap.TweenVars = {
                            opacity: 0,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                start: "top 80%",
                                end: `top 75%`,
                                scrub: 1
                            }
                        }

                        gsap.fromTo(target, {opacity: 1}, animation_object);
                    }
                    break;
                case "rotate-on-scroll":
                    const section: HTMLDivElement | null = element as HTMLDivElement;
                    const rotating_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);

                    rotating_elements.forEach((el) => {
                        gsap.to(el, {
                            rotate: 45,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                end: `bottom top`,
                                start: "top top",
                                scrub: 2
                            }
                        });
                    })
                    break;
                case "speed-horizontal":
                    if (element && document.body.clientWidth >= 100) {
                        const section: HTMLDivElement | null = element as HTMLDivElement;
                        const speeding_elements: NodeListOf<HTMLDivElement> | null = element.querySelectorAll(`[data-scroll-element]`);

                        speeding_elements.forEach((el) => {
                            gsap.to(el, {
                                x: el.dataset.scrollSpeed ?? -100,
                                transformOrigin: "center",
                                ease: "none",
                                scrollTrigger: {
                                    trigger: section,
                                    end: `bottom top`,
                                    start: "top bottom",
                                    scrub: 2
                                }
                            });
                        })
                    }
                    break;
                case "background-change2":
                    if (element) {
                        const section: HTMLDivElement | null = element as HTMLDivElement;
                        const target: HTMLElement | null = document.getElementById(section.dataset.changeTarget ?? "");

                        const animation_object: gsap.TweenVars = {
                            opacity: 1,
                            transformOrigin: "center",
                            ease: "none",
                            scrollTrigger: {
                                trigger: section,
                                start: "top 10%",
                                end: `top 50%`,
                                scrub: 1
                            }
                        }

                        gsap.to(target, animation_object);
                    }
                    break;
            }

        })


    }, [location.pathname]);

    return (
        <></>
    )

}

export default GsapAnimations