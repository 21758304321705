// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDNDf8s9M1mo61oCo7nb09SBLi6jl3ZRYE",
    authDomain: "sk-labs-4f741.firebaseapp.com",
    projectId: "sk-labs-4f741",
    storageBucket: "sk-labs-4f741.appspot.com",
    messagingSenderId: "1037702416746",
    appId: "1:1037702416746:web:f016e5b7ff46b742fd3d13"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);