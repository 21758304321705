import React, {ReactNode, useLayoutEffect} from "react";
import NavigationBar from "../navigation-bar/navigation-bar";
import FooterBar from "../footer-bar/footer-bar";
import LenisScroll from "../lenis-scroll/lenis-scroll";
import GsapAnimations from "../gsap-animations/gsap-animations";
import {getAnalytics, logEvent, isSupported} from "firebase/analytics";
import {app} from "../../firebase";


const Layout = ({children}: { children: ReactNode }) => {

    useLayoutEffect( () => {
        // Log the event when the component mounts

        const analyticsFunc = async () => {
            const isSup = await isSupported()
            if (isSup) {
                const analytics = getAnalytics(app);
                logEvent(analytics, 'page_view', {
                    page_path: location.pathname,
                    page_title: document.title
                });
            }
        }

        analyticsFunc();

    }, [location.pathname]);

    return (
        <>
            {/*Navigation Bar*/}
            <NavigationBar/>

            {/*Main Body*/}
            <LenisScroll/>
            <GsapAnimations/>
            <main className={"bg-[#E6EBEE]"}>
                {children}
            </main>

            {/*Footer*/}

            <FooterBar/>
        </>
    )

}

export default Layout;