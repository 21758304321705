exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-about-index-tsx": () => import("./../../../src/pages/company/about/index.tsx" /* webpackChunkName: "component---src-pages-company-about-index-tsx" */),
  "component---src-pages-company-careers-index-tsx": () => import("./../../../src/pages/company/careers/index.tsx" /* webpackChunkName: "component---src-pages-company-careers-index-tsx" */),
  "component---src-pages-company-partnerships-index-tsx": () => import("./../../../src/pages/company/partnerships/index.tsx" /* webpackChunkName: "component---src-pages-company-partnerships-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-customise-your-fume-hood-index-tsx": () => import("./../../../src/pages/products/customise-your-fume-hood/index.tsx" /* webpackChunkName: "component---src-pages-products-customise-your-fume-hood-index-tsx" */),
  "component---src-pages-products-customise-your-fume-hood-thank-you-index-tsx": () => import("./../../../src/pages/products/customise-your-fume-hood/thank-you/index.tsx" /* webpackChunkName: "component---src-pages-products-customise-your-fume-hood-thank-you-index-tsx" */),
  "component---src-pages-products-product-category-index-tsx": () => import("./../../../src/pages/products/{Product.category}/index.tsx" /* webpackChunkName: "component---src-pages-products-product-category-index-tsx" */),
  "component---src-pages-products-product-category-product-slug-index-tsx": () => import("./../../../src/pages/products/{Product.category}/{Product.slug}/index.tsx" /* webpackChunkName: "component---src-pages-products-product-category-product-slug-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */)
}

