import React, {useEffect} from "react";
import gsap from "gsap";
import {ReactLenis, useLenis} from "@studio-freight/react-lenis";

const LenisScroll = () => {

    const lenis_ref = useLenis();

    useEffect(() => {
        const update: gsap.TickerCallback = (time) => {
            lenis_ref?.raf(time * 1000)
        }

        gsap.ticker.add(update)

        return () => {
            gsap.ticker.remove(update)
        }
    })

    return (
        <ReactLenis root autoRaf={false}/>
    )

}

export default LenisScroll