import React, {useCallback, useLayoutEffect, useMemo} from 'react';
import {graphql, Link, useStaticQuery} from "gatsby";
import {useLocation} from "@gatsbyjs/reach-router";
import Logo from "../logo/logo";
import {Popover, PopoverButton, PopoverPanel, Transition} from "@headlessui/react";
import {getCompanyIcons} from "../../utils/icon-getters";
import {slugify} from "../../utils/slugify";
import gsap from "gsap";
import {skLabsTM} from "../../constants";

const NavigationBar = () => {

    const location = useLocation();

    const { allCategories } = useStaticQuery(graphql`
        query {
            allCategories: allProduct {
                group(field: {category: SELECT}) {
                    fieldValue
                    totalCount
                }
            }
        }
    `);
    const categories: {fieldValue: string, totalCount: number}[] = (allCategories.group as Array<{fieldValue: string, totalCount: number}>).sort((a, b) => {
        if (a.fieldValue === 'Accessories') {
            return 1;
        } else if (b.fieldValue === 'Accessories') {
            return -1;
        } else {
            return 0;
        }
    });

    const COMPANY_TABS = useMemo(() => {

        return [
            {
                title: "About Us",
                slug: "/company/about",
                icon: 'flower'
            }, 
            {
                title: "Our Partnerships",
                slug: "/company/partnerships",
                icon: 'handshake'
            },
            {
                title: "Careers",
                slug: "/company/careers",
                icon: 'people'
            }
        ]

    }, [])

    const NAVIGATION_TABS = useMemo(() => {

        return [
            {
                title: "Company",
                has_popover: true,
                slug: "/company",
                popover_content:
                    <div className={"grid grid-cols-2 gap-4"}>
                        {
                            COMPANY_TABS.map((item, index) => {
                                return (
                                    <Link key={`nav_company_${index}`}  to={item.slug} className="popover-item body-2">
                                        {getCompanyIcons(item.icon, "w-6 h-6 text-primary-light")}
                                        <span>{item.title}</span>
                                    </Link>
                                )
                            })
                        }
                    </div>
            },
            {
                title: "Products",
                has_popover: true,
                slug: "/products",
                popover_content:
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-cols-2 gap-4">
                            {categories.map((category, index) => (
                                <Link key={`nav_category_${index}`} to={`/products/${slugify(category.fieldValue)}`}
                                      className="popover-item body-2">
                                    {getCompanyIcons(category.fieldValue, "w-6 h-6 text-primary-light")}
                                    {category.fieldValue}
                                </Link>
                            ))}
                        </div>
                        <Link to="/products/customise-your-fume-hood"
                              className="bg-zinc-50/75 rounded-xl h-16 font-medium items-center flex justify-between p-3 border hover:text-primary-default hover:bg-primary-light/5 hover:border-primary-default/50 transition duration-300;">
                            Customise your Fume Hood
                            {getCompanyIcons("Fume Hoods", "w-6 h-6 text-primary-light")}
                        </Link>
                    </div>
            },
            {
                title: "Services",
                slug: "/services",
                has_popover: false
            },
            {
                title: "Contact",
                slug: "/contact",
                has_popover: false
            }
        ]

    }, [categories, COMPANY_TABS])

    const animatePopoverIn = useCallback((index: number) => {

        const popover_bg = document.getElementById(`nav-popover-${index}`);
        const popover_inside = document.getElementById(`nav-popover-inside-${index}`);

        gsap.to(popover_bg, {
            height: popover_inside?.clientHeight,
            background: "white",
            duration: 0.4,
            ease: "power4.out"
        })

        gsap.to(popover_inside, {
            opacity: 1,
            duration: 0.3,
            delay: 0.15
        })

    }, [])

    const animatePopoverOut = useCallback((index: number) => {

        const popover_bg = document.getElementById(`nav-popover-${index}`);
        const popover_inside = document.getElementById(`nav-popover-inside-${index}`);

        gsap.set(popover_bg, {height: popover_inside?.clientHeight})

        const tl = gsap.timeline();

        tl.to(popover_bg, {
            height: "44px",
            duration: 0.4,
            ease: "power2.out"
        })

        tl.to(popover_bg, {
            opacity: 0,
            duration: 0.3
        })

        gsap.to(popover_inside, {
            opacity: 0,
            duration: 0.2,
        })

    }, [])

    const onHover = useCallback((index: number) => {
        const to_highlight = document.getElementById(`nav-item-${index}`);
        const hover_highlight = document.getElementById(`hover-highlight`);
        const offsetLeft = to_highlight?.offsetLeft ?? 0;
        const width = to_highlight?.clientWidth ?? 0;

        gsap.to(hover_highlight, {
            x: offsetLeft,
            width: width,
            duration: 0.75,
            opacity: 1,
            ease: "elastic.out(0.75,1)"
        })
    }, [])

    const onLeave = useCallback(() => {
        const hover_highlight = document.getElementById(`hover-highlight`);

        gsap.to(hover_highlight, {
            duration: 0.3,
            opacity: 0,
            overwrite: true
        })
    }, [])


    useLayoutEffect(() => {
        let is_active = -1;

        NAVIGATION_TABS.forEach((item, index) => {
            if (location.pathname.startsWith(item.slug ?? ""))
                is_active = index;
        })


        const active_highlight = document.getElementById('active-highlight');
        if (is_active > -1) {
            const to_highlight = document.getElementById(`nav-item-${is_active}`);
            const offsetLeft = to_highlight?.offsetLeft ?? 0;
            const width = to_highlight?.clientWidth ?? 0;

            gsap.to(active_highlight, {
                x: offsetLeft,
                width: width,
                duration: 0.75,
                opacity: 1,
                ease: "elastic.out(0.75,1)"
            })

        } else {
            gsap.to(active_highlight, {
                duration: 0.75,
                opacity: 0,
                ease: "elastic.out(0.75,1)"
            });
        }
    }, [location.pathname]);




    return (
        <header className={"h-20 custom-container mx-auto flex items-center justify-center sm:justify-start z-50 relative"}>
            <Link to={"/"} className={"flex items-center"} title={"Take me to Home Page"}>
                <Logo className={"h-10"}/>
                <div className={`font-semibold ml-2 text-2xl ${location.pathname.startsWith("/company/about") ? "text-white" : ""}`} style={{ fontFamily: "'Space Grotesk', sans-serif" }}>
                    {skLabsTM}
                </div>
            </Link>
            <nav
                className={"fixed bottom-0 sm:bottom-[unset] sm:top-4 left-1/2 sm:left-[unset] md:left-1/2 -translate-x-1/2 sm:-translate-x-0 md:-translate-x-1/2 right-[unset] sm:right-4 md:right-[unset] bg-white/70 backdrop-saturate-200 backdrop-blur-md rounded-[22px] z-50"}
                style={{
                    boxShadow: "inset 0 0 0 1px #d4d4d8",
                    marginBottom: "calc(env(safe-area-inset-bottom) + 24px)",
                }}
            >
                <div id={"hover-highlight"} aria-hidden={true} className={"origin-left h-8 rounded-full w-0 bg-zinc-200/50 backdrop-saturate-200 absolute left-0 opacity-0 top-1/2 -translate-y-1/2"}/>
                <div id={"active-highlight"} aria-hidden={true} className={"origin-left h-8 rounded-full w-0 bg-primary-default absolute left-0 top-1/2 -translate-y-1/2 z-[55]"}/>
                <ul className={"flex items-center sm:space-x-2 p-1.5 relative"} onMouseLeave={() => { onLeave() }}>
                    {
                        NAVIGATION_TABS.map((item, index) => (
                            <li id={`nav-item-${index}`} key={`nav-item-${index}`} onMouseEnter={() => { onHover(index) }}>
                                {item.has_popover ? (
                                        <Popover>
                                            {
                                                ({open}) => {

                                                    if (open) {
                                                        animatePopoverIn(index);
                                                    } else {
                                                        animatePopoverOut(index)
                                                    }

                                                    return (
                                                        <>
                                                            <PopoverButton id={`nav-item-${index}`} data-link-active={location.pathname.startsWith(item.slug ?? "") ? "active" : "inactive"} className={"nav-item body-3 z-[60]"}>
                                                                {item.title}
                                                            </PopoverButton>
                                                            <Transition
                                                                show={open}
                                                                enter="z-50 duration-700 ease-out"
                                                                enterFrom="z-50 h-[44px]"
                                                                enterTo="z-50"
                                                                leave="z-40 duration-700 ease-out"
                                                                leaveFrom="z-40"
                                                                leaveTo="z-40"
                                                            >
                                                                <PopoverPanel static={true}
                                                                              as="div"
                                                                              id={`nav-popover-${index}`}
                                                                              className="bg-white transition-none absolute bottom-0 sm:bottom-[unset] sm:top-0 left-0 w-full divide-white/5 rounded-[22px] border border-zinc-300 shadow-sm overflow-hidden"
                                                                >
                                                                    <div className={"p-4 pb-12 sm:pb-4 sm:pt-12 opacity-0"} id={`nav-popover-inside-${index}`}>
                                                                        {item.popover_content}
                                                                    </div>
                                                                </PopoverPanel>
                                                            </Transition>
                                                        </>
                                                    )
                                                }
                                            }
                                        </Popover>
                                    ) : (
                                        <Link id={`nav-item-${index}`} data-link-active={location.pathname.startsWith(item.slug ?? "") ? "active" : "inactive"} to={`${item.slug}`} className={"nav-item body-3 z-[60]"}>
                                            {item.title}
                                        </Link>
                                    )
                                }
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </header>
    )
}

export default NavigationBar;